<template>
  <div>
    <!-- Product list table ued zaaval product-table gesen class uguh -->
    <el-table
      v-loading="loading"
      :data="discounts"
      style="width: 100%"
      :show-header="true"
      @cell-click="clickCell">
      <el-table-column label="Гарчиг">
        <template slot-scope="scope">
          <el-row :gutter="10" align="left" type="flex">
            <el-col :span="12">
            <div v-if="scope.row.title_mon">
            {{scope.row.title_mon}}
            </div>
            </el-col>
            </el-row>
        </template>
      </el-table-column>
      <el-table-column label="Хөнгөлөлтийн код">
        <template slot-scope="scope">
          <div>
            <strong>{{scope.row.discount_code}}</strong>
          </div>
          <div>
              <span class="secondary">{{ scope.row.desc_mon }} </span>
          </div>
        </template>
      </el-table-column>1
      <el-table-column label="Төлөв">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.status === 'active'" type="success" effect="dark">Active</el-tag>
          <el-tag v-else-if="scope.row.status === 'expired'" type="info" effect="dark">Expired</el-tag>
          <el-tag v-else type="warning" effect="dark">{{scope.row.status}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="Хэрэглээ хязгаар">
        <template slot-scope="scope">
          <div v-if="scope.row.usage_limit">
            {{scope.row.usage_limit}}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Эхлэх - дуусах огноо">
        <template slot-scope="scope">
          <div v-if="scope.row.end_date">
            {{formatDate(scope.row.start_date)}} - {{formatDate(scope.row.end_date)}}
          </div>
          <div v-else>
            {{formatDate(scope.row.start_date)}} - с эхэлнэ
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @current-change = "curentPageHandler"
      @size-change = "sizeChangeHandler"
      class="text-right mt10"
      background
      :current-page="currentPage"
      :page-size="pageSize"
      :page-sizes="[5, 20, 50, 100]"
      layout="total, sizes, prev, pager, next"
      :total="totalCount"
    >
    </el-pagination>
  </div>
</template>
<script>
import helper from '../../../../helpers/helper'
export default {
  name: 'discountTable',
  props: [
    'discounts',
    'pageSize',
    'totalCount',
    'loading',
    'currentPage',
    'curentPageHandler',
    'sizeChangeHandler',
    'search'
  ],

  methods: {
    clickCell (row, column, cell, event) {
      this.$router.push(
        {
          name: 'detailDiscount',
          params: {
            id: row.id
          },
          query: {
            page: this.currentPage, search: this.search, size: this.pageSize
          }
        }
      )
    },
    formatDate (val) {
      return helper.formatDate(val, 'YYYY/MM/DD')
    }
  }
}
</script>
