<template>
  <div>
    <header class="page-header">
      <el-row :gutter="20" align="middle">
        <el-col :span="12">
          <h2>Хямдралууд</h2>
        </el-col>
      </el-row>
    </header>
    <div class="panel tabled">
      <el-tabs v-model="status" @tab-click="onClickTab">
        <el-tab-pane :label="tab.label" :name="tab.value" v-for="(tab, indexTab) in tabData" :key="indexTab">
          <div class="panel-content">
            <div class="pl20 pr20 table-filter mb10">
              <el-row :gutter="10" align="middle" type="flex">
                <el-col :span="20">
                  <el-input placeholder="Хайх" class="input-with-select" v-model="search" @input="onSearch">
                    <el-button
                      slot="prepend"
                      icon="el-icon-search"
                    ></el-button>
                  </el-input>
                </el-col>
                <el-col :span="4" :offset="4" class="text-right">
                  <el-dropdown @command="handleSort">
                  <el-button type="default" class="lowercase">Эрэмбэлэх <i class="el-icon-sort"></i></el-button>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item :command = 'filter.value' v-for="(filter, indexFilter) in dropdownDataFilter" :key="indexFilter">{{filter.label}}</el-dropdown-item>
                       </el-dropdown-menu>
                  </el-dropdown>
               </el-col>
              </el-row>
            </div>
            <discount-table :discounts="discounts" :loading="loading" :pageSize="pageSize"  :currentPage="currentPage" :totalCount="totalCount" :sizeChangeHandler="sizeChangeHandler" :curentPageHandler="curentPageHandler"></discount-table>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import services from '../../../helpers/services'
import DiscountTable from './components/discountTable'
export default {
  name: 'orderList',
  components: {
    DiscountTable
  },
  data () {
    return {
      totalCount: 0,
      discounts: [],
      loading: false,
      activeTabStatus: 'all',
      pageSize: 20,
      currentPage: 1,
      search: '',
      channel: 'ECOMMERCE',
      status: 'all',
      dropdownDataFilter: [
        {
          label: 'Нэр А - Я',
          value: 'firstChar'
        }, {
          label: 'Нэр Я - А',
          value: 'lastChar'
        }, {
          label: 'Шинэ эхэнд',
          value: 'newest'
        }, {
          label: 'Хуучин эхэнд',
          value: 'oldest'
        }
      ],
      tabData: [
        {
          label: 'All',
          value: 'all'
        }, {
          label: 'Active',
          value: 'active'
        }, {
          label: 'Expired',
          value: 'expired'
        }
      ]
    }
  },

  created () {
    var from = (this.pageSize * (this.currentPage - 1))
    var size = this.pageSize
    if (this.$route.query.page && this.$route.query.size) {
      const incomingPage = parseFloat(this.$route.query.page)
      const incomingSize = parseFloat(this.$route.query.size)
      const incomingSearch = this.$route.query.search
      const incomingStatus = this.$route.query.status
      this.status = incomingStatus
      this.currentPage = incomingPage
      this.pageSize = incomingSize
      this.search = incomingSearch
      from = (this.pageSize * (incomingPage - 1))
      size = incomingSize
    }
    this.getDiscounts(from, size, this.search, this.channel, this.status === 'all' ? '' : this.status)
  },

  methods: {
    onSearch () {
      this.$router.push({ name: 'autoDiscount', query: { page: this.currentPage, size: this.pageSize, search: this.search, channel: this.channel, status: this.status } }).catch(() => {})
      this.getDiscounts(this.pageSize * (this.currentPage - 1), this.pageSize, this.search, this.channel, this.status === 'all' ? '' : this.status)
    },

    sizeChangeHandler (item) {
      this.pageSize = item
      this.$router.push({ name: 'autoDiscount', query: { page: this.currentPage, size: this.pageSize, search: this.search, channel: this.channel, status: this.status } }).catch(() => {})
      this.getDiscounts((this.pageSize * (this.currentPage - 1)), this.pageSize, this.search, this.channel, this.status === 'all' ? '' : this.status)
    },

    curentPageHandler (item) {
      this.currentPage = item
      this.$router.push({ name: 'autoDiscount', query: { page: item, size: this.pageSize, search: this.search, channel: this.channel, status: this.status } }).catch(() => {})
      this.getDiscounts((this.pageSize * (this.currentPage - 1)), this.pageSize, this.search, this.channel, this.status === 'all' ? '' : this.status)
    },

    sortMethods (method, data) {
      if (method === 'firstChar') {
        data.sort((a, b) => a.title_mon.localeCompare(b.title_mon))
      } else if (method === 'lastChar') {
        data.sort((a, b) => b.title_mon.localeCompare(a.title_mon))
      } else if (method === 'newest') {
        data.sort((a, b) => b.createdAt.localeCompare(a.createdAt))
      } else if (method === 'oldest') {
        data.sort((a, b) => a.createdAt.localeCompare(b.createdAt))
      }
      return data
    },

    handleSort (data) {
      this.search = ''
      this.discounts = this.sortMethods(data, this.discounts)
    },

    getDiscounts (from, size, search, channel, status) {
      const query = '?search_text=' + search + '&from=' + from + '&size=' + size + '&type=AUTO' + '&channel=' + channel + '&status=' + status
      this.loading = true
      services.getDiscounts(query).then(response => {
        this.totalCount = response.total
        this.discounts = response.discounts
        this.loading = false
      })
    },

    onClickTab (data) {
      if (data.label === 'All') {
        this.status = 'all'
      } else if (data.label === 'Active') {
        this.status = 'active'
      } else if (data.label === 'Expired') {
        this.status = 'expired'
      }
      this.currentPage = 1
      this.$router.push({ name: 'autoDiscount', query: { page: 1, size: this.pageSize, search: this.search, channel: this.channel, status: this.status } }).catch(() => {})
      this.getDiscounts(0, this.pageSize, this.search, this.channel, this.status === 'all' ? '' : this.status)
    }
  }
}
</script>
